import { useFormikContext } from "formik";
import * as pdfMake from "pdfmake/build/pdfmake";
import { useCompanyInfo } from "../../../../hooks/useCompanyInfo";
import { pdfFonts, useFindBranchWarehouses, useImageMap } from "../../moo-config";
import { getDateFormat, getDollarNumberWithCommas } from "../../moo-shared";
import { One } from "../@types/one";

function useGetItemBody() {
	const { values } = useFormikContext<One>();

	return async (): Promise<any[][]> => {
		return values.items.map((item, index) => {
			return [
				(index + 1).toString(),
				item.id,
				{ image: item.id, width: 60, height: 60 },
				item.name,
				item.spec,
				item.count,
				getDollarNumberWithCommas(item.price_final),
			];
		});
	};
}

function useGetBody() {
	const getItemBody = useGetItemBody();

	return async () => {
		let arr: any[][] = [
			[{ text: "訂貨商品", style: "header", colSpan: 7 }, {}, {}, "", "", "", ""],
			["編號", "商品編號", "圖片", "品名", "規格", "數量", "價格"],
		];

		arr = arr.concat(await getItemBody());

		arr.push([{}, {}, {}, "", "", "", ""]);
		return arr;
	};
}

export function useOnPdfClick() {
	const getBody = useGetBody();
	const { values, isValid } = useFormikContext<One>();
	const imageMap = useImageMap({ items: values.items });
	const { address, tel, fax } = useCompanyInfo();
	const nickName = values.employee?.nick_name || "";
	const getWarehouses = useFindBranchWarehouses();

	return async (id: string) => {
		if (isValid) {
			const FILE_NAME = `訂貨單-${id}`;

			const content = [
				{
					style: "defaultTable",
					table: {
						headerRows: 1,
						widths: [80, "*", "auto"],
						body: [
							[
								{ image: "logo", width: 51, height: 64, margin: [0, 10, 0, 0] },
								[
									{ text: address, margin: [0, 18, 0, 3] },
									{ text: `TEL:${tel}`, margin: [0, 3] },
									{ text: `FAX:${fax}`, margin: [0, 3] },
								],
								[
									{
										text: values.order_id
											? `訂購單 ${values.order_id || ""}${values.order?.employee?.nick_name || ""}`
											: "暫無",
										fontSize: 16,
										bold: true,
										margin: [0, 11, 0, 3],
									},
									{
										columns: [
											{ text: "業務", width: "auto", margin: [0, 3] },
											{ text: values.sales_name, width: "*", margin: [0, 3] },
										],
									},
									{
										columns: [
											{ text: "電話", width: "auto", margin: [0, 3] },
											{ text: values.sales_tel, width: "*", margin: [0, 3] },
										],
									},
								],
							],
							[
								{
									stack: [
										{ text: "廠商", margin: [0, 3, 0, 3] },
										{ text: "電話", margin: [0, 3, 0, 3] },
										{ text: "傳真", margin: [0, 3, 0, 3] },
										{ text: "Email", margin: [0, 3] },
									],
									border: [false, true, false, true],
								},
								{
									stack: [
										{ text: values.manufacturer_name, margin: [0, 3, 0, 3] },
										{ text: values.manufacturer_tel, margin: [0, 3, 0, 3] },
										{ text: values.manufacturer_tel, margin: [0, 3, 0, 3] },
										{ text: values.manufacturer_email, margin: [0, 3] },
									],
									border: [false, true, false, true],
								},
								{
									stack: [
										{
											columns: [
												{ text: "訂貨日期", width: "auto", margin: [0, 3, 0, 3] },
												{
													text: values.order_goods_date === null ? "" : getDateFormat(values.order_goods_date),
													width: "*",
													margin: [0, 3],
												},
											],
										},
										{
											columns: [
												{ text: "交貨日期", width: "auto", margin: [0, 3, 0, 3] },
												{
													text: values.deliver_goods_date === null ? "" : getDateFormat(values.deliver_goods_date),
													width: "*",
													margin: [0, 3],
												},
											],
										},
										{
											columns: [
												{ text: "收貨倉庫", width: "auto", margin: [0, 3, 0, 3] },
												{
													text: (await getWarehouses()).find((e) => e.id === values.warehouse_id)?.name || "",
													width: "*",
													margin: [0, 3],
												},
											],
										},
									],
									border: [false, true, false, true],
								},
							],
							[
								{
									stack: [{ text: "訂貨單", margin: [0, 3, 0, 3] }],
									border: [false, true, false, true],
								},
								{
									stack: [{ text: `${id}${nickName}`, margin: [0, 3, 0, 3] }],
									border: [false, true, false, true],
								},
								{
									stack: [],
									border: [false, true, false, true],
								},
							],
							[
								{
									stack: [{ text: "備註", margin: [0, 3, 0, 3] }],
									border: [false, true, false, true],
								},
								{
									stack: [{ text: values.note, margin: [0, 3, 0, 3] }],
									border: [false, true, false, true],
								},
								{
									stack: [],
									border: [false, true, false, true],
								},
							],
						],
					},
					layout: {
						defaultBorder: false,
						hLineColor: "silver",
					},
				},
				{
					style: "defaultTable",
					color: "#444",
					table: {
						widths: [20, 55, 60, "*", "*", 22, 50],
						headerRows: 1,
						body: await getBody(),
					},
					layout: "lightHorizontalLines",
				},
			];

			pdfMake
				.createPdf(
					{
						content,
						images: imageMap,
						styles: {
							header: {
								fontSize: 16,
								bold: true,
							},
							warning: {
								color: "red",
							},
							bigger: {
								fontSize: 15,
								italics: true,
							},
							defaultTable: {
								margin: [0, 5, 0, 5],
							},
							tableHeader: {
								bold: true,
								fontSize: 13,
								color: "grey",
							},
						},
						defaultStyle: {
							columnGap: 15,
							fontSize: 10,
							font: "chineseFont",
						},
					},
					undefined,
					pdfFonts,
				)
				.download(FILE_NAME);
		}
	};
}
